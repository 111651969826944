/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import { RendererProvider, ThemeProvider } from "react-fela";
import { createRenderer } from "fela";
import { rehydrate } from "fela-dom";
import config from "./fela.config.js";
import theme from "./fela.theme.js";
import globalStyle from "./src/styles/base.css.js";
import fonts from "./src/fonts";
import { ApolloProvider } from "@apollo/client";
import { client } from "./src/utilities/apollo/client";

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  const renderer = createRenderer(config);

  renderer.renderStatic(globalStyle);
  fonts.forEach((font) => {
    renderer.renderFont(font.name, font.files, font.options);
  });
  rehydrate(renderer);

  return (
    <RendererProvider renderer={renderer}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </ThemeProvider>
    </RendererProvider>
  );
};
