exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-signatures-js": () => import("./../../../src/pages/signatures.js" /* webpackChunkName: "component---src-pages-signatures-js" */),
  "component---src-templates-aboutus-page-index-tsx": () => import("./../../../src/templates/aboutus-page/index.tsx" /* webpackChunkName: "component---src-templates-aboutus-page-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/blog-page/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-case-studies-page-index-tsx": () => import("./../../../src/templates/case-studies-page/index.tsx" /* webpackChunkName: "component---src-templates-case-studies-page-index-tsx" */),
  "component---src-templates-comics-page-index-tsx": () => import("./../../../src/templates/comics-page/index.tsx" /* webpackChunkName: "component---src-templates-comics-page-index-tsx" */),
  "component---src-templates-consult-page-index-tsx": () => import("./../../../src/templates/consult-page/index.tsx" /* webpackChunkName: "component---src-templates-consult-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/home-page/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-job-index-tsx": () => import("./../../../src/templates/job/index.tsx" /* webpackChunkName: "component---src-templates-job-index-tsx" */),
  "component---src-templates-jobs-page-index-tsx": () => import("./../../../src/templates/jobs-page/index.tsx" /* webpackChunkName: "component---src-templates-jobs-page-index-tsx" */),
  "component---src-templates-joinus-page-index-tsx": () => import("./../../../src/templates/joinus-page/index.tsx" /* webpackChunkName: "component---src-templates-joinus-page-index-tsx" */),
  "component---src-templates-landing-campaign-page-index-tsx": () => import("./../../../src/templates/landing-campaign-page/index.tsx" /* webpackChunkName: "component---src-templates-landing-campaign-page-index-tsx" */),
  "component---src-templates-posters-page-index-tsx": () => import("./../../../src/templates/posters-page/index.tsx" /* webpackChunkName: "component---src-templates-posters-page-index-tsx" */),
  "component---src-templates-safe-course-page-index-tsx": () => import("./../../../src/templates/safe-course-page/index.tsx" /* webpackChunkName: "component---src-templates-safe-course-page-index-tsx" */),
  "component---src-templates-slides-page-index-tsx": () => import("./../../../src/templates/slides-page/index.tsx" /* webpackChunkName: "component---src-templates-slides-page-index-tsx" */),
  "component---src-templates-tiny-tales-page-index-tsx": () => import("./../../../src/templates/tiny-tales-page/index.tsx" /* webpackChunkName: "component---src-templates-tiny-tales-page-index-tsx" */),
  "component---src-templates-training-page-index-tsx": () => import("./../../../src/templates/training-page/index.tsx" /* webpackChunkName: "component---src-templates-training-page-index-tsx" */)
}

