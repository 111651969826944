import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  if (process.env.NODE_ENV !== 'development') {
    return headers
  }
  return {
    headers: {
      ...headers,
      authorization: process.env.SANITY_TOKEN ? `Bearer ${process.env.SANITY_TOKEN}` : "",
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(new HttpLink({
    uri: `https://${process.env.SANITY_PROJECT_ID}.apicdn.sanity.io/v1/graphql/${process.env.SANITY_DATASET}/default`,
    fetch,
  })),
  cache: new InMemoryCache()
})
