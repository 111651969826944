import SoehneWebExtraleichtEot  from './soehne-web-extraleicht.eot';
import SoehneWebExtraleichtWoff  from './soehne-web-extraleicht.woff';
import SoehneWebExtraleichtWoff2  from './soehne-web-extraleicht.woff2';
import SoehneWebExtraleichtKursivEot  from './soehne-web-extraleicht-kursiv.eot';
import SoehneWebExtraleichtKursivWoff  from './soehne-web-extraleicht-kursiv.woff';
import SoehneWebExtraleichtKursivWoff2  from './soehne-web-extraleicht-kursiv.woff2';
import SoehneWebLeichtEot  from './soehne-web-leicht.eot';
import SoehneWebLeichtWoff  from './soehne-web-leicht.woff';
import SoehneWebLeichtWoff2  from './soehne-web-leicht.woff2';
import SoehneWebLeichtKursivEot  from './soehne-web-leicht-kursiv.eot';
import SoehneWebLeichtKursivWoff  from './soehne-web-leicht-kursiv.woff';
import SoehneWebLeichtKursivWoff2  from './soehne-web-leicht-kursiv.woff2';
import SoehneWebBuchEot  from './soehne-web-buch.eot';
import SoehneWebBuchWoff  from './soehne-web-buch.woff';
import SoehneWebBuchWoff2  from './soehne-web-buch.woff2';
import SoehneWebBuchKursivEot  from './soehne-web-buch-kursiv.eot';
import SoehneWebBuchKursivWoff  from './soehne-web-buch-kursiv.woff';
import SoehneWebBuchKursivWoff2  from './soehne-web-buch-kursiv.woff2';
import SoehneWebKraftigEot  from './soehne-web-kraftig.eot';
import SoehneWebKraftigWoff  from './soehne-web-kraftig.woff';
import SoehneWebKraftigWoff2  from './soehne-web-kraftig.woff2';
import SoehneWebKraftigKursivEot  from './soehne-web-kraftig-kursiv.eot';
import SoehneWebKraftigKursivWoff  from './soehne-web-kraftig-kursiv.woff';
import SoehneWebKraftigKursivWoff2  from './soehne-web-kraftig-kursiv.woff2';
import SoehneWebHalbfettEot  from './soehne-web-halbfett.eot';
import SoehneWebHalbfettWoff  from './soehne-web-halbfett.woff';
import SoehneWebHalbfettWoff2  from './soehne-web-halbfett.woff2';
import SoehneWebHalbfettKursivEot  from './soehne-web-halbfett-kursiv.eot';
import SoehneWebHalbfettKursivWoff  from './soehne-web-halbfett-kursiv.woff';
import SoehneWebHalbfettKursivWoff2  from './soehne-web-halbfett-kursiv.woff2';
import SoehneWebDreiviertelfettEot  from './soehne-web-dreiviertelfett.eot';
import SoehneWebDreiviertelfettWoff  from './soehne-web-dreiviertelfett.woff';
import SoehneWebDreiviertelfettWoff2  from './soehne-web-dreiviertelfett.woff2';
import SoehneWebDreiviertelfettKursivEot  from './soehne-web-dreiviertelfett-kursiv.eot';
import SoehneWebDreiviertelfettKursivWoff  from './soehne-web-dreiviertelfett-kursiv.woff';
import SoehneWebDreiviertelfettKursivWoff2  from './soehne-web-dreiviertelfett-kursiv.woff2';
import SoehneWebFettEot  from './soehne-web-fett.eot';
import SoehneWebFettWoff  from './soehne-web-fett.woff';
import SoehneWebFettWoff2  from './soehne-web-fett.woff2';
import SoehneWebFettKursivEot  from './soehne-web-fett-kursiv.eot';
import SoehneWebFettKursivWoff  from './soehne-web-fett-kursiv.woff';
import SoehneWebFettKursivWoff2  from './soehne-web-fett-kursiv.woff2';
import SoehneWebExtrafettEot  from './soehne-web-extrafett.eot';
import SoehneWebExtrafettWoff  from './soehne-web-extrafett.woff';
import SoehneWebExtrafettWoff2  from './soehne-web-extrafett.woff2';
import SoehneWebExtrafettKursivEot  from './soehne-web-extrafett-kursiv.eot';
import SoehneWebExtrafettKursivWoff  from './soehne-web-extrafett-kursiv.woff';
import SoehneWebExtrafettKursivWoff2  from './soehne-web-extrafett-kursiv.woff2';
import SoehneMonoWebLeichtEot  from './soehne-mono-web-leicht.eot';
import SoehneMonoWebLeichtWoff  from './soehne-mono-web-leicht.woff';
import SoehneMonoWebLeichtWoff2  from './soehne-mono-web-leicht.woff2';
import SoehneMonoWebLeichtKursivEot  from './soehne-mono-web-leicht-kursiv.eot';
import SoehneMonoWebLeichtKursivWoff  from './soehne-mono-web-leicht-kursiv.woff';
import SoehneMonoWebLeichtKursivWoff2  from './soehne-mono-web-leicht-kursiv.woff2';
import SoehneMonoWebBuchEot  from './soehne-mono-web-buch.eot';
import SoehneMonoWebBuchWoff  from './soehne-mono-web-buch.woff';
import SoehneMonoWebBuchWoff2  from './soehne-mono-web-buch.woff2';
import SoehneMonoWebBuchKursivEot  from './soehne-mono-web-buch-kursiv.eot';
import SoehneMonoWebBuchKursivWoff  from './soehne-mono-web-buch-kursiv.woff';
import SoehneMonoWebBuchKursivWoff2  from './soehne-mono-web-buch-kursiv.woff2';
import TiemposTextWebRegularEot  from './tiempos-text-web-regular.eot';
import TiemposTextWebRegularWoff  from './tiempos-text-web-regular.woff';
import TiemposTextWebRegularWoff2  from './tiempos-text-web-regular.woff2';
import TiemposTextWebMediumEot  from './tiempos-text-web-medium.eot';
import TiemposTextWebMediumWoff  from './tiempos-text-web-medium.woff';
import TiemposTextWebMediumWoff2  from './tiempos-text-web-medium.woff2';

export const fontFamilySansSerif = "Soehne, Helvetica, Arial, sans-serif"
export const fontFamilySerif     = "Tiempos, Georgia, 'Times New Roman', Times, serif"
export const fontFamilyMonospace = "SoehneMono, Monaco, Consolas, 'Courier New', monospace"

export default [
  {
    name: 'Soehne',
    files: [SoehneWebExtraleichtEot,SoehneWebExtraleichtWoff,SoehneWebExtraleichtWoff2],
    options: {
      fontWeight: '200',
      fontStyle: 'normal',
    },
  },
  {
    name: 'Soehne',
    files: [SoehneWebExtraleichtKursivEot, SoehneWebExtraleichtKursivWoff, SoehneWebExtraleichtKursivWoff2],
    options: {
      fontWeight: '200',
      fontStyle: 'italic',
    }
  },
  {
    name: 'Soehne',
    files: [SoehneWebLeichtEot, SoehneWebLeichtWoff, SoehneWebLeichtWoff2],
    options: {
      fontWeight: '300',
      fontStyle: 'normal',
    }
  },
  {
    name: 'Soehne',
    files: [SoehneWebLeichtKursivEot, SoehneWebLeichtKursivWoff, SoehneWebLeichtKursivWoff2],
    options: {
      fontWeight: '300',
      fontStyle: 'italic',
    }
  },
  {
    name: 'Soehne',
    files: [SoehneWebBuchEot, SoehneWebBuchWoff, SoehneWebBuchWoff2],
    options: {
      fontWeight: '400',
      fontStyle: 'normal',
    }
  },
  {
    name: 'Soehne',
    files: [SoehneWebBuchKursivEot, SoehneWebBuchKursivWoff, SoehneWebBuchKursivWoff2],
    options: {
      fontWeight: '400',
      fontStyle: 'italic',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebKraftigEot, SoehneWebKraftigWoff, SoehneWebKraftigWoff2],
    options: {
      fontWeight: '500',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebKraftigKursivEot, SoehneWebKraftigKursivWoff, SoehneWebKraftigKursivWoff2],
    options: {
      fontWeight: '500',
      fontStyle: 'italic',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebHalbfettEot, SoehneWebHalbfettWoff, SoehneWebHalbfettWoff2],
    options: {
      fontWeight: '600',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebHalbfettKursivEot, SoehneWebHalbfettKursivWoff, SoehneWebHalbfettKursivWoff2],
    options: {
      fontWeight: '600',
      fontStyle: 'italic',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebDreiviertelfettEot, SoehneWebDreiviertelfettWoff, SoehneWebDreiviertelfettWoff2],
    options: {
      fontWeight: '700',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebDreiviertelfettKursivEot, SoehneWebDreiviertelfettKursivWoff, SoehneWebDreiviertelfettKursivWoff2],
    options: {
      fontWeight: '700',
      fontStyle: 'italic',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebFettEot, SoehneWebFettWoff, SoehneWebFettWoff2],
    options: {
      fontWeight: '800',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebFettKursivEot, SoehneWebFettKursivWoff, SoehneWebFettKursivWoff2],
    options: {
      fontWeight: '800',
      fontStyle: 'italic',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebExtrafettEot, SoehneWebExtrafettWoff, SoehneWebExtrafettWoff2],
    options: {
      fontWeight: '900',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'Soehne',
    files: [SoehneWebExtrafettKursivEot, SoehneWebExtrafettKursivWoff, SoehneWebExtrafettKursivWoff2],
    options: {
      fontWeight: '900',
      fontStyle: 'italic',
    }
  }
  
  ,
  {
    name: 'SoehneMono',
    files: [SoehneMonoWebLeichtEot, SoehneMonoWebLeichtWoff, SoehneMonoWebLeichtWoff2],
    options: {
      fontWeight: '300',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'SoehneMono',
    files: [SoehneMonoWebLeichtKursivEot, SoehneMonoWebLeichtKursivWoff, SoehneMonoWebLeichtKursivWoff2],
    options: {
      fontWeight: '300',
      fontStyle: 'italic',
    }
  }
  ,
  {
    name: 'SoehneMono',
    files: [SoehneMonoWebBuchEot, SoehneMonoWebBuchWoff, SoehneMonoWebBuchWoff2],
    options: {
      fontWeight: '400',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'SoehneMono',
    files: [SoehneMonoWebBuchKursivEot, SoehneMonoWebBuchKursivWoff, SoehneMonoWebBuchKursivWoff2],
    options: {
      fontWeight: '400',
      fontStyle: 'italic',
    }
  } ,
  {

    name: 'Tiempos',
    files: [TiemposTextWebRegularEot, TiemposTextWebRegularWoff, TiemposTextWebRegularWoff2],
    options: {
      fontWeight: '300',
      fontStyle: 'normal',
    }
  }
  ,
  {
    name: 'Tiempos',
    files: [TiemposTextWebMediumEot, TiemposTextWebMediumWoff, TiemposTextWebMediumWoff2],
    options: {
      fontWeight: '500',
      fontStyle: 'italic',
    }
  },
]
