import felaPluginValidator from "fela-plugin-validator";
import felaPluginFriendlyPseudoClass from "fela-plugin-friendly-pseudo-class";
import felaPluginExpandShorthand from "fela-plugin-expand-shorthand";
import extend from "fela-plugin-extend";
import felaPluginNamedKeys from "fela-plugin-named-keys";
import devPreset from "fela-preset-dev";
import { createWebPreset } from "fela-preset-web";

const namedKeys = {
  allUp: "@media (min-width: 320px)",
  desktopAndUp: "@media (min-width: 1200px)",
  tabletLandscapeAndUp: "@media (min-width: 900px)",
  tabletPortraitAndUp: "@media (min-width: 600px)",
  phoneOnly: "@media (max-width: 599px)",
  betweenMinAndMax: "@media (min-width: 320px) and (max-width: 1200px)",
  maxAndAbove: "@media (min-width: 1199px)",
  ipadMiniAndUp: "@media (min-width: 768px)",
  ipadMiniAndDown: "@media (max-width: 768px)",
};

const defaultUnit = "px";

export default {
  plugins: [
    extend(),
    ...createWebPreset({
      unit: [defaultUnit],
    }),
    felaPluginFriendlyPseudoClass(),
    felaPluginNamedKeys(namedKeys),
    felaPluginExpandShorthand(true),
    // ...(process.env.NODE_ENV === "development" && devPreset),
  ].filter(Boolean),

  enhancers: [
  ],
  devMode: process.env.NODE_ENV !== "production",
};
