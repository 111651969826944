const globalStyle = `
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
main {
  display: block;
}
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
a {
  background-color: transparent;
}
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
b,
strong {
  font-weight: 700;
}
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
small {
  font-size: 80%;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
select {
  margin: 0;
}
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
input {
  overflow: visible;
}
legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
select {
  text-transform: none;
}
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
details {
  display: block;
}
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
body {
    background-color: #FBFCFC;
    /* background-color: blue; */
    color: #302739;
    color: var(--color-console-500);
    font-family: Soehne, Helvetica, Arial, sans-serif;
    font-family: var(--font-family-sans-serif);
    font-size: 13px;
    margin: 0;
    position: relative;
    font-variant-ligatures: none;
}
@media (min-width: 600px) {
body {
        font-size: 17px
}
    }
@media (min-width: 1200px) {
body {
        font-size: 20px
}
    }
.h2, .h3, .h4, .h5,
h2, h3, h4, h5 {
    font-family: Soehne, Helvetica, Arial, sans-serif;
    font-family: var(--font-family-sans-serif);
}
.h1, h1 {
    font-family: Tiempos, Georgia, "Times New Roman", Times, serif;
    font-family: var(--font-family-serif);
    font-size: 36px;
    font-weight: 400;
    line-height: 120px
}
@media (min-width: 600px) {
.h1, h1 {
        font-size: 58px
}
    }
@media (min-width: 1200px) {
.h1, h1 {
        font-size: 72px
}
    }
.h2, h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 64px
}
@media (min-width: 600px) {
.h2, h2 {
        font-size: 43px
}
    }
@media (min-width: 1200px) {
.h2, h2 {
        font-size: 56px
}
    }
.h3, h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 46px
}
@media (min-width: 600px) {
.h3, h3 {
        font-size: 32px
}
    }
@media (min-width: 1200px) {
.h3, h3 {
        font-size: 42px
}
    }
.h4, h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 38px
}
@media (min-width: 600px) {
.h4, h4 {
        font-size: 28px
}
    }
@media (min-width: 1200px) {
.h4, h4 {
        font-size: 34px
}
    }
.h5, h5 {
    font-family: soehne-mono, Monaco, Consolas, "Courier New", monospace;
    font-family: var(--font-family-monospace);
    font-size: 12px;
    font-weight: 400;
    line-height: 30px
}
@media (min-width: 600px) {
.h5, h5 {
        font-size: 18px
}
    }
@media (min-width: 1200px) {
.h5, h5 {
        font-size: 24px
}
    }
a {
    background-color: transparent;
    color: #302739;
    color: var(--color-console-500);
    text-decoration: none
}
a:active, a:hover {
        color: #302739;
        color: var(--color-console-500);
        text-decoration: none;
    }
figure img {
    width: 100%;
    /* margin-left: -25%; */
}
.hide {
    display: none;
}`
export default globalStyle
